import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, take } from "rxjs";
import { environment } from "../../environments/environment";
import { Search } from "../interfaces/search/search.model";

@Injectable({
    providedIn: "root",
})
export class SearchService {
    private searchRes = new BehaviorSubject<Search | null>(null);

    searchRes$ = this.searchRes.asObservable();

    constructor(private http: HttpClient) {}

    search(query: string, currPage: number) {
        let params = new HttpParams().set("q", query).set("per_page", 24).set("page", currPage);
        return this.http
            .post<Search>(`${environment.API_UNA}${environment.API_SEARCH}`, null, {
                params,
            })
            .pipe(take(1))
            .subscribe((res: Search) => {
                this.searchRes.next(res);
            });
    }
}
